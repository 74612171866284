@use "src/common/styles" as *;
.Setup {
  width: 100%;
  height: 150%;
  background-color: $very-light-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.finishedBtn {
  outline: none;
  padding: 10px;
  color: white;
  font-weight: bold;
  border: 1px solid teal;
  border-radius: 5px;
  font-size: 18px;
  padding-left: 35px;
  padding-right: 35px;
  align-self: center;
  width: fit-content;
  align-self: center;
  transition: 200ms;
  background-color: teal;
  &:hover {
    cursor: pointer;
    color: teal;
    background-color: white;
    border: 1px solid teal;
  }
}
.header {
  font-weight: bold;
  font-size: 26px !important;
}
.inputs {
  border: none;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 6%), 0px 3px 6px rgb(0 0 0 / 4%);
  background-color: white;
  outline: transparent solid 2px;
  transition: 200ms;
  margin-left: 15px;
  &:focus {
    outline: rgba($color: teal, $alpha: 0.7) solid 2px;
  }
  padding: 10px;
}
.guide {
  background-color: white;
  padding: 10px;
  overflow: hidden;
  min-height: 40%;
  transition: 200ms;
  width: 70%;
}
.step {
  font-size: 18px;
  padding: 10px;
}
.inputStep {
  display: flex;
  background-color: $very-light-gray;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  width: fit-content;
  flex-direction: row;
  align-items: center;
}
.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}
.image {
  width: 50%;
}
