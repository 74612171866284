.SignUp {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgb(230, 230, 230);
  width: 100%;
  height: 100%;
}
.middleBlob {
  padding: 5px;
  width: 30%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: white;
  align-items: center;
  position: absolute;
  top: 20%;
  left: 34.5%;
  .image {
    width: 35%;
  }
  .button {
    &:hover {
      cursor: pointer;
      background-color: rgb(0, 167, 0);
    }
    outline: none;
    border: none;
    transition: 200ms;
    border-radius: 5px;
    background-color: green;
    color: white;
    font-weight: bold;
    width: 40%;
    font-size: 18px;
    padding: 5px;
  }
  .title {
    font-size: 32px;
    font-weight: bold;
  }
}
