@use "src/common/styles" as *;

.Account {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: $very-light-gray;
}
.centerBlock {
  width: 60%;
  height: 60%;
  background-color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.step {
  font-size: 18px;
  padding: 10px;
}
.header {
  font-weight: bold;
  font-size: 24px;
}
.inputStep {
  display: flex;
  background-color: $very-light-gray;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  width: fit-content;
  flex-direction: row;
  align-items: center;
}
.inputs {
  border: none;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 6%), 0px 3px 6px rgb(0 0 0 / 4%);
  background-color: white;
  outline: transparent solid 2px;
  transition: 200ms;
  margin-left: 15px;
  &:focus {
    outline: rgba($color: teal, $alpha: 0.7) solid 2px;
  }
  padding: 10px;
}
.loadingContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.updateIdsForm {
  display: flex;
  flex-direction: column;
}
.submitBtn {
  outline: none;
  padding: 8px;
  color: white;
  font-weight: bold;
  margin-left: 10px;
  border: 1px solid teal;
  border-radius: 5px;
  font-size: 16px;
  padding-left: 25px;
  padding-right: 25px;
  transition: 200ms;
  background-color: teal;
  &:disabled {
    background-color: rgba($color: teal, $alpha: 0.7);
    border-color: rgba($color: teal, $alpha: 0.7);
    &:hover {
      cursor: auto;
      color: white;
      background-color: rgba($color: teal, $alpha: 0.7);
      border-color: rgba($color: teal, $alpha: 0.7);
    }
  }
  &:hover {
    cursor: pointer;
    color: teal;
    background-color: white;
    border: 1px solid teal;
  }
}
.title {
  font-size: 20px;
  font-weight: bold;
}
.cancelBtn {
  border: 2px solid red;
  color: red;
  background-color: white;
  outline: none;
  border-radius: 10px;
  font-weight: bold;
  transition: 200ms;
  padding: 10px;
  font-size: 18px;
  width: fit-content;
  &:hover {
    cursor: pointer;
    color: white;
    background-color: red;
  }
  align-self: center;
}
.cancelContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}
.btnsContainer {
  width: 80%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.yesBtn {
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  &:hover {
    cursor: pointer;
  }
}
.noBtn {
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: teal;
  color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  padding-left: 25px;
  padding-right: 25px;
  &:hover {
    cursor: pointer;
  }
}
