@use "src/common/styles" as *;

.SubscriptionPurchase {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  background-color: $very-light-gray;
}

.leftBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
}
.rightBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
}
.blob {
  width: 50%;
  height: 50%;
  background-color: white;
  padding: 20px;
}
