@use "src/common/styles" as *;

.Subscription {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $very-light-gray;
}
.title {
  width: 50%;
  height: 15%;
  font-size: 34px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-bottom: 20px;
}
.subscriptionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 60%;
  height: 50%;
  background-color: white;
  .plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 30%;
    height: 80%;
    border: 1px solid darkgray;
    border-radius: 10px;
    transition: 200ms;
    padding: 10px;
    .mainPriceInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      .generalSub {
        font-size: 32px;
        font-weight: bold;
      }
      .discountContainer {
        display: flex;
        width: 55%;
        align-items: center;
        justify-content: space-between;
        .oldPrice {
          text-decoration: line-through;
          color: darkgray;
        }
        .discount {
          color: red;
          font-size: 18px;
        }
      }
      .asIfSub {
        font-size: 20px;
        color: darkgray;
      }
    }
    .capabilities {
      text-align: center;
      font-weight: bold;
      color: darkgray;
    }
    .getStartedBtn {
      outline: none;
      padding: 10px;
      color: white;
      font-weight: bold;
      border: 1px solid teal;
      border-radius: 5px;
      font-size: 18px;
      padding-left: 25px;
      padding-right: 25px;
      transition: 200ms;
      background-color: teal;
      &:hover {
        cursor: pointer;
        color: teal;
        background-color: white;
        border: 1px solid teal;
      }
    }
  }
}
