@use "src/common/styles" as *;

.SubscriptionForm {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
}

.inputs {
  border: none;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 6%), 0px 3px 6px rgb(0 0 0 / 4%);
  background-color: white;
  outline: transparent solid 2px;
  transition: 200ms;
  &:focus {
    outline: rgba($color: teal, $alpha: 0.7) solid 2px;
  }
  padding: 10px;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .inputs {
    width: 46%;
  }
}

.purchaseSummary {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: bold;
  font-size: 18px;
  .line {
    padding-top: 10px;
    padding-bottom: 10px;
    .cost {
      font-weight: normal;
    }
  }
}

.issue {
  color: red;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header {
  font-size: 26px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.submitBtn {
  outline: none;
  padding: 10px;
  color: white;
  font-weight: bold;
  border: 1px solid teal;
  border-radius: 5px;
  font-size: 18px;
  padding-left: 35px;
  padding-right: 35px;
  width: fit-content;
  align-self: center;
  transition: 200ms;
  background-color: teal;
  &:hover {
    cursor: pointer;
    color: teal;
    background-color: white;
    border: 1px solid teal;
  }
}
