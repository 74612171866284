$orange: #ff993b;
$white: #fff;
$light-gray: lightgray;
$teal: teal;
$very-light-gray: rgb(230, 230, 230);
$green: green;
$light-green: rgb(0, 167, 0);

:export {
  orange: $orange;
  white: $white;
  light-gray: $light-gray;
  very-light-gray: $very-light-gray;
  green: $green;
  light-green: $light-green;
}
