.Header {
  width: 100%;
  height: 6%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: lightgray 0px 0px 3px 3px;
}

.logo {
  padding: 20px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 28px;
}

.linksContainer {
  width: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  .link {
    color: teal;
    border: 2px solid teal;
    text-decoration: none;
    font-weight: bold;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    transition: 200ms;
    &:hover {
      background-color: teal;
      color: white;
    }
  }
}
