@use "src/common/styles" as *;

.Home {
  height: 120%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $very-light-gray;
}
.image {
  width: 90%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 6%) 0px 0px 4px 6px;
}
.centerBlock {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  justify-content: space-around;
  .leftText {
    font-weight: bold;
    color: teal;
    font-size: 32px;
    text-align: left;
    width: 40%;
    .link {
      font-weight: normal;
      font-size: 22px;
    }
  }
  .imageContainer {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
.footer {
  width: 100%;
  height: 20%;
  background-color: teal;
}
